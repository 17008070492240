import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';
import './Linechart.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import logo from '../../assets/images/logo.png';

const LineChart = () => {
  const chartRef = useRef(null);

  useEffect(() => {
    // Sample data for the graph
    const data = {
      labels: ["08/01/21", "09/01/21", "10/01/21", "11/01/21", "12/01/21", "01/01/21", "02/01/21", "03/01/21","04/01/21"],
      datasets: [
        {
          label: "Best-in-class",
          borderColor: "#008036",
          borderDash: [4, 4],
          data: [7, 5.5, 6, 6, 8, 6, 7, 8, 7 ],
          fill: false,
        },
        {
          label: "Your fleet",
          borderColor: "#000",
          data: [9, 8, 9, 9, 9.5, 9.5, 10, 11, 10],
          fill: false,
        },
        {
          label: "Average of other fleets",
          borderColor: "#EF985A",
          borderDash: [4, 4],
          data: [13.5, 14, 12.2, 12.2, 13, 13.1, 14, 15, 14],
          fill: false,
        },
      ],
    };

    const config = {
      type: 'line',
      data: data,
      options: {
        responsive: true,
        maintainAspectRatio: true,
        scales: {
          x: {
            display: true,
            title: {
              display: true,
            },
          },
          y: {
            display: true,
            title: {
              display: true,
              text: 'Fuel Economy (L/100km)',
            },
          },
        },
        plugins: {
          legend: {
            display: false, // Set this to false to hide the legend
          },
        },
      },
    };

    // Create the chart
    const myChart = new Chart(chartRef.current, config);

    // Cleanup on component unmount
    return () => myChart.destroy();
  }, []);

  return (
    <div className="container-fluid mt-3">
      <div className="d-flex justify-content-between mb-3 align-items-center chart-tabs">
        <div className="d-flex chart-tabs-head">
          <div className="nav nav-tabs" id="chartTabs">
            <button className="nav-link active" style={{ backgroundColor: '#008036', color:'#fff', borderRadius:'20px', fontSize:'15px' }}>Fuel economy</button>
            <button className="nav-link" style={{ backgroundColor: '#F1F1F1', color:'#000', borderRadius:'20px', fontSize:'15px' }}>Fuel Usage</button>
            <button className="nav-link" style={{ backgroundColor: '#F1F1F1', color:'#000', borderRadius:'20px', fontSize:'15px' }}>Downtime</button>
            <button className="nav-link" style={{ backgroundColor: '#F1F1F1', color:'#000', borderRadius:'20px', fontSize:'15px' }}>Maintenance</button>
          </div>
        </div>
        <div className='chart-tabs-logo' style={{position:'relative'}}>
          <span className='chart-dot' style={{width:'14px', height: '14px', backgroundColor:'#008036', borderRadius:'50%', position:'absolute', left:'-18px ', top:'10px'}}></span>
          <img src={logo} alt="Logo" style={{ width: '75px', }} />
        </div>
      </div>
      <div className='tabs-content'>
        <h3>Fuel Usage</h3>
        <p>Your ICE ‘s average fuel economy, compared to similar fleets in the Navfuel ecosystem.</p>
      </div>
        <canvas id="lineChart" ref={chartRef}></canvas>
      <div className="d-flex justify-content-start mt-3">
      <div className="mx-2 d-flex">
        <div className="rounded-circle d-flex justify-content-center align-items-center" style={{ width: '25px', height: '25px' }}>
          <div style={{backgroundColor:'#fff', width: '10px', height:'10px', borderRadius:'50%'}}></div>
        </div>
        <div>
          <p className="text-right ms-3 text-dark" style={{fontSize:'15px'}}>Best-in-class</p>
        </div>
      </div>
      <div className="mx-2 d-flex">
        <div className="rounded-circle d-flex justify-content-center align-items-center bg-dark" style={{ width: '25px', height: '25px' }}>
          <div style={{backgroundColor:'#fff', width: '10px', height:'10px', borderRadius:'50%'}}></div>
        </div>
        <div>
          <p className="text-right ms-3 text-dark" style={{fontSize:'15px'}}>Your fleet</p>
        </div>
      </div>
      <div className="mx-2 d-flex">
        <div className="rounded-circle d-flex justify-content-center align-items-center circle-last" style={{ width: '25px', height: '25px' }}>
          <div style={{backgroundColor:'#fff', width: '10px', height:'10px', borderRadius:'50%'}}></div>
        </div>
        <div>
          <p className="text-right ms-3 text-dark" style={{fontSize:'15px'}}>Average of other fleets</p>
        </div>
      </div>
    </div>

    </div>
  );
};

export default LineChart;