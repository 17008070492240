import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import {useNavigate} from 'react-router-dom'
import { useAppStore } from '../../AppStore';

// import css
import './Sidebar.css'

// import material ui icons
import TuneRoundedIcon from '@mui/icons-material/TuneRounded';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import InsertChartOutlinedRoundedIcon from '@mui/icons-material/InsertChartOutlinedRounded';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import PersonAddAlt1OutlinedIcon from '@mui/icons-material/PersonAddAlt1Outlined';

const drawerWidth = 230;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(9)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(11)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

const MenuItem = styled(ListItem)(({ theme, isActive }) => ({
  display: 'block',
  marginBottom:'12px',
  backgroundColor: isActive ? '#008036' : 'inherit',
  borderRadius: '10px',
  transition: theme.transitions.create(['background-color', 'border-radius'], {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function Sidebar() {
  const theme = useTheme();
  // const [open, setOpen] = React.useState(true);
  const [activeMenu, setActiveMenu] = React.useState(null); // Track active menu
  const navigate = useNavigate();

  const open = useAppStore((state) => state.dopen);

  const handleMenuClick = (menuName) => {
    setActiveMenu(menuName);
    navigate(`/`);
  };

   // Define content for each menu item
   const menuContent = {
    Dashboard: <h2>Dashboard Content</h2>,
    ManageTrips: <h2>Manage Trips Content</h2>,
    Scheduling: <h2>Scheduling Content</h2>,
    Analytics: <h2>Analytics Content</h2>,
    DriverPerfomance: <h2>Driver Perfomance Content</h2>,
    Settings: <h2>Settings Content</h2>,
    Login: <h2>Login Content</h2>,
    Register: <h2>Register Content</h2>,
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Box height={50}/>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />

        {/* search bar */}
        <Box sx={{ display: 'flex', alignItems:'center', padding:'5px 12px' }}>
          <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 1 : 'auto',
                  justifyContent: 'center',
                }}
              >
            <SearchOutlinedIcon style={{color:'#8F95B2', fontSize:'24px',}}/>
          </ListItemIcon>
          <ListItemText className='search-text' style={{color:'#8F95B2',}} primary="Search" sx={{ opacity: open ? 1 : 0 }} />
        </Box>
        <List>
          
          {/* Customisation list */}
          <Box sx={{ display: 'flex', alignItems:'center', marginBottom:'15px' }}>
            <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 1 : 'auto',
                  justifyContent: 'center',
                }}
              >
              <TuneRoundedIcon style={{color:'#8F95B2', fontSize:'20px'}}  />
            </ListItemIcon>
            <ListItemText className='search-text' style={{color:'#8F95B2', fontSize:'17px'}} primary="Customization" sx={{ opacity: open ? 1 : 0 }} />
          </Box>

          {/* Dashboard list */}
          <MenuItem 
            disablePadding
            onClick={() => navigate("/Dashboard")}
            isActive={activeMenu === null}
            // onClick={() => handleMenuClick(null)}
          >
            <ListItemButton className='sidebar-menus'>
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                  color:'#fff'
                }}
              >
                <DashboardOutlinedIcon style={{color:'#fff'}} />
              </ListItemIcon>
              <ListItemText  style={{color:'#fff',}} primary="Dashboard" sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </MenuItem>

          {/* ManageTrips list */}
          <MenuItem  disablePadding onClick={() => navigate("/ManageTrips")}>
            <ListItemButton className='sidebar-menus'>
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                <ShareOutlinedIcon style={{color:'#8F95B2'}}  />
              </ListItemIcon >
              <ListItemText  style={{color:'#8F95B2'}} primary="ManageTrips" sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </MenuItem>

          {/* Scheduling list */}
          <MenuItem disablePadding onClick={() => navigate("/Scheduling")}>
            <ListItemButton className='sidebar-menus'>
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                <ArticleOutlinedIcon style={{color:'#8F95B2'}} />
              </ListItemIcon>
              <ListItemText style={{color:'#8F95B2'}} primary="Scheduling" sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </MenuItem>

          {/* Full Map list */}
          <MenuItem disablePadding onClick={() => navigate("/Fullmap")}>
            <ListItemButton className='sidebar-menus'>
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                <ArticleOutlinedIcon style={{color:'#8F95B2'}} />
              </ListItemIcon>
              <ListItemText style={{color:'#8F95B2'}} primary="Full Map" sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </MenuItem>

          {/* Analytics list */}
          <MenuItem disablePadding onClick={() => navigate("/Analytics")}>
            <ListItemButton className='sidebar-menus'>
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                <InsertChartOutlinedRoundedIcon style={{color:'#8F95B2'}} />
              </ListItemIcon>
              <ListItemText style={{color:'#8F95B2'}} primary="Analytics" sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </MenuItem>

          {/* DriverPerfomance list */}
          <MenuItem disablePadding onClick={() => navigate("/DriverPerfomance")}>
            <ListItemButton className='sidebar-menus'>
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                <PersonOutlineIcon style={{color:'#8F95B2'}} />
              </ListItemIcon>
              <ListItemText style={{color:'#8F95B2' }} primary="DriverPerfomance" sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </MenuItem>

          {/* Settings list */}
          <MenuItem disablePadding onClick={() => navigate("/Settings")}>
            <ListItemButton className='sidebar-menus'>
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                <SettingsOutlinedIcon style={{color:'#8F95B2'}} />
              </ListItemIcon>
              <ListItemText style={{color:'#8F95B2'}} primary="Settings" sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </MenuItem>
              
          {/* Login page */}
          <MenuItem disablePadding
            onClick={() => handleMenuClick(null)}
           >
            <ListItemButton className='sidebar-menus'>
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                <LockOutlinedIcon style={{color:'#8F95B2'}} />
              </ListItemIcon>
              <ListItemText style={{color:'#8F95B2'}} primary="Login" sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </MenuItem>
          
          {/* RegisterForm page */}
          <MenuItem disablePadding onClick={() => navigate("/RegisterForm")}>
            <ListItemButton className='sidebar-menus'>
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                <PersonAddAlt1OutlinedIcon style={{color:'#8F95B2'}} />
              </ListItemIcon>
              <ListItemText style={{color:'#8F95B2'}} primary="Register" sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </MenuItem>

        </List>
      </Drawer>
      {/* Render Dashboard content based on active state */}
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        {activeMenu && menuContent[activeMenu]}
      </Box>
    </Box>
  );
}
