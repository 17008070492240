// LoginPage.js
import React, { useState } from 'react'
import './Login.css'
import { useNavigate } from 'react-router-dom'
import loginImage from '../../assets/images/logo.png'
import axios from 'axios'

const Login = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')
  const navigate = useNavigate()

  const handleLogin = async () => {
    try {
      let url = `https://navfuel.mahad.ai/mahad_api/api/auth/login?email=${email}&password=${password}`
      let response = await axios.post(url)
      console.log(response.data)
      localStorage.setItem('token', response.data.access_token)
      if (response.status === 200) {
        setError('')
        navigate('/Dashboard')
      } else {
        setError('Invalid email or password')
      }
    } catch (err) {
      console.error(err)
    }
  }

  const styles = {
    container: {
      textAlign: 'left',
      maxWidth: '700px',
      margin: 'auto',
      marginTop: '4rem',
      padding: '20px',
      borderRadius: '8px',
      boxShadow: '0 0 9px rgba(0, 0, 0, 0.1)'
    },
    image: {
      width: '20%',
      marginBottom: '40px'
    },
    title: {
      fontSize: '30px',
      color: '#333',
      fontWeight: 'bold'
    },
    para: {
      color: 'gray',
      marginBottom: '35px'
    },
    formGroup: {
      marginBottom: '15px'
    },
    input: {
      width: '100%',
      padding: '15px',
      boxSizing: 'border-box',
      borderRadius: '6px',
      border: '1px solid #ccc',
      color: 'green'
    },
    button: {
      width: '100%',
      padding: '15px',
      boxSizing: 'border-box',
      borderRadius: '4px',
      border: 'none',
      backgroundColor: 'green',
      color: '#fff',
      cursor: 'pointer',
      fontWeight: 'bold',
      fontSize: '18px'
    },
    errorMessage: {
      color: 'red',
      marginTop: '10px'
    },
    link: {
      marginTop: '10px'
    }
  }

  return (
    <div className='login-container' style={styles.container}>
      <img src={loginImage} alt='Login' style={styles.image} />
      <h1 style={styles.title}>Sign In</h1>
      <p style={styles.para}>Sign in on the internal platform</p>
      <div className='form-group' style={styles.formGroup}>
        <input
          placeholder='Enter user email'
          type='text'
          value={email}
          onChange={e => setEmail(e.target.value)}
          style={styles.input}
        />
      </div>
      <div className='form-group' style={styles.formGroup}>
        <input
          placeholder='Enter user password'
          type='password'
          value={password}
          onChange={e => setPassword(e.target.value)}
          style={styles.input}
        />
      </div>
      <button
        className='singin-btn'
        type='submit'
        onClick={handleLogin}
        style={styles.button}
      >
        Sign In now
      </button>
      {error && (
        <div className='error-message' style={styles.errorMessage}>
          {error}
        </div>
      )}
      <p style={styles.link}>
        Don't have an account?
        <a
          onClick={() => navigate('/RegisterForm')}
          className='signin-link'
          href='#'
        >
          Sign up
        </a>{' '}
      </p>
    </div>
  )
}

export default Login
