
import {Routes, Route, BrowserRouter, Navigate} from 'react-router-dom';
import Login from './pages/Login/Login';
import Dashboard from './pages/Dashboard/Dashboard';
import ManageTrips from './pages/ManageTrips/ManageTrips';
import Scheduling from './pages/Scheduling/Scheduling';
import Fullmap from './pages/Fullmap/Fullmap';
import Analytics from './pages/Analytics/Analytics';
import DriverPerfomance from './pages/DriverPerfomance/DriverPerfomance';
import Settings from './pages/Settings/Settings';
import RegisterForm from './pages/Register/RegisterForm';
// import Login from './pages/Login/Login';

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path='/Dashboard' exact element={<Dashboard/>}></Route>
          <Route path='/ManageTrips' exact element={<ManageTrips/>}></Route>
          <Route path='/Scheduling' exact element={<Scheduling/>}></Route>
          <Route path='/Fullmap' exact element={<Fullmap/>}></Route>
          <Route path='/Analytics' exact element={<Analytics/>}></Route>
          <Route path='/DriverPerfomance' exact element={<DriverPerfomance/>}></Route>
          <Route path='/Settings' exact element={<Settings/>}></Route>
          <Route path='/RegisterForm' exact element={<RegisterForm/>}></Route>  
          <Route path='/' element={<Login />} />
          {/* Use Navigate to redirect from one route to another */}
          <Route path='/Login' element={<Navigate to='/Dashboard' />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
