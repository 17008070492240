import React from 'react'
// import axios from 'axios';

import Box from '@mui/material/Box';
import Navbar from '../../components/Navbar/Navbar'
import Sidebar from '../../components/Sidebar/Sidebar'
import DrawerHeader from '@mui/material/Drawer'

const ManageTrips = () => {
    return (
       <>
        <Navbar/>
        <Box height={40}/>
        <Box sx={{ display: 'flex' }}>
          <Sidebar/>
          <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
              <DrawerHeader/>
              <h1>Manage Trips</h1>
          </Box>
       </Box>
       </>
    )
  }
export default ManageTrips;
