import React, { Component } from 'react'
import './Reports.css'
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';
import ProgressCircularChart from '../ProgressCircularChart/ProgressCircularChart';

export default class Reports extends Component {
  render() {
    return (
      <div>
        <Grid container spacing={2}>
            <Grid item xs={9}>
                <Box style={{backgroundColor:'#fff', padding:'20px', borderRadius:'20px', marginTop:'20px'}}>
                    <Typography gutterBottom  style={{color:'#000', fontSize:'16px', marginBottom:'15px', fontWeight:'bold'}}>Reports</Typography>
                    <Stack direction="row" spacing={2}>
                        <Card sx={{ maxWidth: 255, width:'32%', height:'auto', borderRadius:'17px', backgroundColor:'#F7FAFF', boxShadow:'none'}}>
                            <CardContent >
                                <Typography style={{fontSize:'16px', color:'#8F95B2'}} gutterBottom variant="h6" component="div">
                                    Geofence Alert
                                </Typography>
                                <Typography variant="h5" color="#081735" fontWeight='bold'>
                                    2
                                </Typography>
                            </CardContent>
                        </Card>
                        <Card sx={{ maxWidth: 255, width:'32%', height:'auto', borderRadius:'17px', backgroundColor:'#F7FAFF', boxShadow:'none'}}>
                            <CardContent >
                                <Typography style={{fontSize:'16px', color:'#8F95B2'}} gutterBottom variant="h6" component="div">
                                Battery Fault
                                </Typography>
                                <Typography variant="h5" color="#081735" fontWeight='bold'>
                                    6
                                </Typography>
                            </CardContent>
                        </Card>
                        <Card sx={{ maxWidth: 255, width:'32%', height:'auto', borderRadius:'17px', backgroundColor:'#F7FAFF', boxShadow:'none'}}>
                            <CardContent >
                                <Typography style={{fontSize:'16px', color:'#8F95B2'}} gutterBottom variant="h6" component="div">
                                Sever Fault
                                </Typography>
                                <Typography variant="h5" color="#081735" fontWeight='bold'>
                                    10
                                </Typography>
                            </CardContent>
                        </Card>
                        <Card sx={{ maxWidth: 255, width:'32%', height:'auto', borderRadius:'17px', backgroundColor:'#F7FAFF', boxShadow:'none'}}>
                            <CardContent >
                                <Typography style={{fontSize:'16px', color:'#8F95B2'}} gutterBottom variant="h6" component="div">
                                Fatigued Drivers
                                </Typography>
                                <Typography variant="h5" color="#081735" fontWeight='bold'>
                                    7
                                </Typography>
                            </CardContent>
                        </Card>
                    </Stack>
                </Box>
            </Grid>
            <Grid item xs={3}>
                <Box style={{ height:'90%', backgroundColor:'#fff', padding:'30px 20px', borderRadius:'20px', marginTop:'20px'}}>
                    <Typography  style={{color:'#000', fontSize:'15px', marginBottom:'5px', marginTop:'-8px', fontWeight:'bold'}}>Safety Scorecard</Typography>
                    <ProgressCircularChart percentage={83}/>
                </Box>
            </Grid>
        </Grid>
      </div>
    )
  }
}
