import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Button } from '@mui/material'
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined'
import { withGoogleMap, GoogleMap, Marker, InfoWindow } from 'react-google-maps'
import InfoWindowContent from '../../components/InfoWindowContent'

const Map = withGoogleMap(props => (
  <GoogleMap defaultCenter={props.markers[0]} defaultZoom={13}>
    {props.markers.map((marker, index) => (
      <Marker
        key={index}
        position={{ lat: marker.lat, lng: marker.lng }}
        title={marker.title}
        icon={{
          url: `data:image/svg+xml;utf-8,${encodeURIComponent(
            '<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="48" height="48" viewBox="0 0 172 172" style=" fill:#26e07f;"><g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><path d="M0,172v-172h172v172z" fill="none"></path><g fill="#1fb141"><path d="M21.5,21.5v129h64.5v-32.25v-64.5v-32.25zM86,53.75c0,17.7805 14.4695,32.25 32.25,32.25c17.7805,0 32.25,-14.4695 32.25,-32.25c0,-17.7805 -14.4695,-32.25 -32.25,-32.25c-17.7805,0 -32.25,14.4695 -32.25,32.25zM118.25,86c-17.7805,0 -32.25,14.4695 -32.25,32.25c0,17.7805 14.4695,32.25 32.25,32.25c17.7805,0 32.25,-14.4695 32.25,-32.25c0,-17.7805 -14.4695,-32.25 -32.25,-32.25z"></path></g></g></svg>'
          )}`,
          scaledSize: new window.google.maps.Size(32, 32)
        }}
        onClick={() => props.onMarkerClick(index)}
      >
        {props.selectedMarkerIndex === index && (
          <InfoWindow onCloseClick={props.onInfoWindowClose}>
            <InfoWindowContent
              imei={props.markers[props.selectedMarkerIndex].imei}
            />
          </InfoWindow>
        )}
      </Marker>
    ))}
  </GoogleMap>
))

const MyMapComponent = () => {
  const [markers, setMarkers] = useState([])
  const [selectedMarkerIndex, setSelectedMarkerIndex] = useState(null)

  useEffect(() => {
    const fetchVehicles = async () => {
      try {
        let response = await axios.get(
          'https://navfuel.mahad.ai/mahad_api/api/auth/devices',
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
              'Content-Type': 'application/json'
            }
          }
        )

        if (response.data && response.data.data) {
          let data = response.data.data
          let newMarkers = data.map(vehicle => ({
            title: vehicle.vehicleName,
            lat: vehicle.lastLat,
            lng: vehicle.lastLng,
            imei: vehicle.imei,
            isHighlighted: false
          }))
          setMarkers(newMarkers)
        }
      } catch (err) {
        console.log(err)
      }
    }

    fetchVehicles()
  }, [])

  const handleMarkerClick = index => {
    setSelectedMarkerIndex(index)
  }

  const handleInfoWindowClose = () => {
    setSelectedMarkerIndex(null)
  }

  return (
    <>
      <div>
        <Link to='/Dashboard'>
          <Button
            style={{
              margin: '10px',
              textTransform: 'capitalize',
              fontSize: '17px',
              fontWeight: 'bold'
            }}
            variant='outlined'
            color='success'
          >
            <ArrowBackOutlinedIcon /> Dashboard
          </Button>
        </Link>
      </div>
      <Map
        markers={markers}
        selectedMarkerIndex={selectedMarkerIndex}
        onMarkerClick={handleMarkerClick}
        onInfoWindowClose={handleInfoWindowClose}
        containerElement={<div style={{ height: '544px' }} />}
        mapElement={<div style={{ height: '100%' }} />}
      />
    </>
  )
}

export default MyMapComponent
