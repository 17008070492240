// VehicleRegistration.js
import React, { useState } from 'react';
import './Popup.css'; // Import a separate CSS file for styling

const Popup = () => {
  const [isPopupOpen, setPopupOpen] = useState(false);

  const openPopup = () => {
    setPopupOpen(true);
  };

  const closePopup = () => {
    setPopupOpen(false);
  };

  return (
    <div>
      <button
        className="register-button"
        onClick={openPopup}
      >
        Register Vehicles
      </button>

      {isPopupOpen && (
        <div className="popup-overlay">
          <div className="popup">
            <button className="close-button" onClick={closePopup}>Close</button>
            <h3>Vehicles Registration</h3>
            <hr />  
            <form className='popup-inputs'>
                <input placeholder='Enter Vehicles Number' type="text" /><br />
                <input placeholder='Enter Device IMEI Number' type="text" /><br />
                <input placeholder='Enter Vehicle Type' type="text" /><br />
                <input placeholder='Enter Manufacturer Name' type="text" /><br />
                <input placeholder='Enter Model Name' type="text" /><br />
                <input placeholder='Enter Year of Manufacture' type="text" /><br />
                <button type="submit" onClick={closePopup}>Register</button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default Popup;
     