import React, { useEffect, useState } from 'react'
import axios from 'axios'

const InfoWindowContent = ({ imei }) => {
  let [info, setInfo] = useState(null)
  useEffect(() => {
    const fetchDetails = async () => {
      try {
        let response = await axios.get(
          `https://navfuel.mahad.ai/mahad_api/api/auth/live?imei=${imei}`
        )
        setInfo(response.data.data)
      } catch (err) {}
    }
    fetchDetails()
  }, [imei])
  return (
    <div style={{ padding: '10px', background: 'white', color: 'grey' }}>
      <div style={{ marginBottom: '10px', fontWeight: 'bold', color: 'black' }}>
        Cell No:{' '}
        <span style={{ fontWeight: 'bold', color: 'black' }}>
          {info?.simPhoneNumber}
        </span>
      </div>
      <div>
        <span>Driver: {info?.driverName}</span>
      </div>
      <div style={{ marginTop: '10px', color: 'black' }}>
        <span style={{ color: 'red' }}>●</span> Last Updated:{' '}
        {info?.lastUpdateUITime}
      </div>
      <br style={{ borderBottom: '1px solid lightgrey' }} />
      <div style={{ marginTop: '10px' }}>
        <div>
          Status:{' '}
          <span style={{ color: 'green' }}>
            {info?.isActive === 'Yes' ? 'Running' : 'Idle'}
          </span>
        </div>
        <div>Distance: {info?.distance}</div>
        <div>Location: {info?.address}</div>
      </div>
    </div>
  )
}

export default InfoWindowContent
