import React, { useState } from 'react'
import './Dashboard.css'
import Box from '@mui/material/Box'
import Navbar from '../../components/Navbar/Navbar'
import Sidebar from '../../components/Sidebar/Sidebar'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined'
import { IconButton, Stack } from '@mui/material'
import LineChart from '../../components/Chart/Linechart'
import Reports from '../../components/Reports/Reports'
import MyMapComponent from '../../components/Map/Map'
import Button from '@mui/material/Button'
import axios from 'axios'
import { Link } from 'react-router-dom'

const Dashboard = () => {
  const [summary, setSummary] = useState(null)

  useState(() => {
    const getSummary = async () => {
      try {
        let response = await axios.get(
          'https://navfuel.mahad.ai/mahad_api/api/auth/devices/summary',
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
              'Content-Type': 'application/json'
            }
          }
        )
        console.log(response.data.data)
        setSummary(response.data.data)

        localStorage.setItem('summary', JSON.stringify(response.data.data))
      } catch (err) {
        console.error(err)
      }
    }
    getSummary()
  }, [])

  return (
    <>
      {/* <Navbar componenet */}
      <Navbar />
      <Box height={50} />
      <Box sx={{ display: 'flex' }}>
        {/* <Sidebar componenet */}
        <Sidebar />
        <Box component='main' sx={{ flexGrow: 1, p: 3 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              {/* Cards component */}
              <Grid item xs={12} sm={5} md={6}>
                <Card
                  className='card-main'
                  sx={{
                    maxWidth: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '21px 0',
                    borderRadius: '17px'
                  }}
                >
                  {
                    <CardContent className='bord'>
                      <Typography
                        className='card-content-text'
                        style={{
                          fontSize: '24px',
                          color: '#8F95B2',
                          fontWeight: 'bold'
                        }}
                        gutterBottom
                        variant='h6'
                        component='div'
                      >
                        Maintenance_Cost
                      </Typography>
                      <Typography
                        className='card-content-text-number'
                        variant='h5'
                        color='text.dark'
                        fontWeight='bold'
                      >
                        {summary?.maintenance_cost || '$18,127'}
                      </Typography>
                    </CardContent>
                  }
                  <CardContent
                    className='card-content-header'
                    style={{ borderLeft: '1px solid lightgray' }}
                  >
                    <Typography
                      className='card-content-text'
                      style={{
                        fontSize: '24px',
                        color: '#8F95B2',
                        fontWeight: 'bold'
                      }}
                      gutterBottom
                      variant='h6'
                      component='div'
                    >
                      Fuel Cost
                    </Typography>
                    <Typography
                      className='card-content-text-number'
                      color='text.dark'
                      fontWeight='bold'
                      variant='h5'
                    >
                      {summary?.monthly.fuel_cost}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={4} sm={3} md={2}>
                <Card
                  className='distance-card-head'
                  sx={{
                    maxWidth: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '5px 0',
                    borderRadius: '17px',
                    textAlign: 'center'
                  }}
                >
                  <CardContent>
                    <IconButton className='bg-icon'>
                      <LocalShippingOutlinedIcon style={{ color: '#340BFF' }} />
                    </IconButton>
                    <Typography
                      className='distance-card-text'
                      style={{
                        fontSize: '24px',
                        color: '#000',
                        fontWeight: 'bold',
                        marginBottom: '0'
                      }}
                      gutterBottom
                      variant='h6'
                      component='div'
                    >
                      {summary?.monthly.distance_travelled}{' '}
                      <span style={{ color: '#8F95B2', fontSize: '10px' }}>
                        Km
                      </span>
                    </Typography>
                    <Typography
                      className='distance-card'
                      variant='p'
                      color='#8F95B2'
                      fontSize='13px'
                    >
                      Distance_Covered
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={4} sm={2} md={2}>
                <Card
                  sx={{
                    maxWidth: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '5px 0',
                    borderRadius: '17px',
                    textAlign: 'center'
                  }}
                >
                  <CardContent>
                    <IconButton className='bg-icon2'>
                      <LocalShippingOutlinedIcon style={{ color: '#FF7846' }} />
                    </IconButton>
                    <Typography
                      className='distance-card-text'
                      style={{
                        fontSize: '25px',
                        color: '#000',
                        fontWeight: 'bold',
                        marginBottom: '0'
                      }}
                      gutterBottom
                      variant='h6'
                      component='div'
                    >
                      {summary?.moving_count}
                    </Typography>
                    <Typography variant='p' color='#8F95B2' fontSize='14px'>
                      Enroute
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={4} sm={2} md={2}>
                <Card
                  sx={{
                    maxWidth: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '5px 0',
                    borderRadius: '17px',
                    textAlign: 'center'
                  }}
                >
                  <CardContent>
                    <IconButton className='bg-icon3'>
                      <LocalShippingOutlinedIcon style={{ color: '#FF3FF7' }} />
                    </IconButton>
                    <Typography
                      className='distance-card-text'
                      style={{
                        fontSize: '25px',
                        color: '#000',
                        fontWeight: 'bold',
                        marginBottom: '0'
                      }}
                      gutterBottom
                      variant='h6'
                      component='div'
                    >
                      {summary?.idle_count}
                    </Typography>
                    <Typography variant='p' color='#8F95B2' fontSize='14px'>
                      Idle
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>

            {/* chart component */}
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  sx={{
                    backgroundColor: '#fff',
                    padding: '10px',
                    borderRadius: '20px',
                    marginTop: '15px'
                  }}
                >
                  <LineChart />
                </Box>
              </Grid>
            </Grid>

            {/* Reports Component */}
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Reports />
              </Grid>
            </Grid>

            {/* Map Component  */}
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  sx={{
                    backgroundColor: '#fff',
                    padding: '15px',
                    borderRadius: '20px',
                    marginTop: '20px'
                  }}
                >
                  <Stack direction='row' marginBottom={2}>
                    <Typography
                      style={{ fontSize: '20px', fontWeight: 'bold' }}
                    >
                      Real Time - Tracking
                    </Typography>
                    <Link style={{ marginLeft: 'auto' }} to='/Fullmap'>
                      <Button
                        variant='contained'
                        style={{
                          borderRadius: '9px',
                          backgroundColor: '#008036',
                          textTransform: 'capitalize'
                        }}
                      >
                        See Full Map
                      </Button>
                    </Link>
                  </Stack>
                  <MyMapComponent />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default Dashboard
