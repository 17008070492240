import * as React from 'react';
import './Navbar.css'
import { styled } from '@mui/material/styles';
import MuiAppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Img from '../../assets/images/logo.png'
import Typography from '@mui/material/Typography';
import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu'
import AccountCircle from '@mui/icons-material/AccountCircle';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import MoreIcon from '@mui/icons-material/MoreVert';
import { useAppStore } from '../../AppStore';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import Popup from '../Popup/Popup';


const AppBar = styled(MuiAppBar, {
})(({ theme}) => ({
  zIndex: theme.zIndex.drawer + 1,
}));


const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: 'ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}));



export default function Navbar() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const updateOpen = useAppStore((state) => state.updateOpen);
  const dopen = useAppStore((state) => state.dopen);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={handleMenuClose}>My account</MenuItem>
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <Popup/>
      </MenuItem>
      <MenuItem>
        <Stack direction="row" spacing={2}>
            <Avatar sx={{ width: 35, height: 35, borderRadius:'8px' }} alt="Travis Howard" src="././assets/images/profile.png" />
        </Stack>
      </MenuItem>
      <MenuItem>
        <Stack direction="row" spacing={2}>
        <Avatar sx={{ width: 35, height: 35, borderRadius:'8px' }} alt="Cindy Baker" src="/static/images/avatar/3.jpg" />
        </Stack>
      </MenuItem>
      <MenuItem>
        <IconButton
          size="large"
          color="inherit"
        >
        <StyledBadge
            overlap="circular"
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            variant="dot"
            >
            <NotificationsNoneOutlinedIcon style={{fontSize:'28px', marginLeft:'-8px',  color:'#000'}} />
          </StyledBadge>
        </IconButton>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
          sx={{marginTop:'-10px'}}
        >
          <AccountCircle   style={{marginLeft:'-8px', width: 30, height: 30}} />
        </IconButton>
      </MenuItem>
      <Box sx={{display:'flex', alignItems:'start', flexDirection:'column', padding:'5px'}}>
        <Typography style={{fontSize:'14px', color:'#000', fontWeight:'bold'}}>Velvet Onion</Typography>
        <Typography style={{fontSize:'14px', color:'#8F95B2', fontWeight:'normal'}}>velvet@deliver.com</Typography>
      </Box>
    </Menu>
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="fixed" style={{backgroundColor:'#FCFCFC'}}>
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="#000"
            aria-label="open drawer"
            sx={{ mr: 0 }}
            onClick={()=>updateOpen(!dopen)}
          >
            <MenuIcon />
          </IconButton>
          <div className='header-logo'>
            <img src={Img} alt="img" />
          </div>
          <Typography style={{fontSize:'18px', color:'#081735', fontWeight:'700',marginLeft:'5rem'}}>
            Hello, Velvet👋
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { xs: 'none', md: 'flex', alignItems:'center' } }}>
            <Stack direction="row" spacing={2}>
            <Popup/>
              <Avatar sx={{ width: 35, height: 35, borderRadius:'8px' }} alt="Travis Howard" src="././assets/images/profile.png" />
              <Avatar sx={{ width: 35, height: 35, borderRadius:'8px' }} alt="Cindy Baker" src="/static/images/avatar/3.jpg" />
          </Stack>
            <IconButton
            sx={{ width: 35, height: 35 }}
              size="large"
              color="inherit"
            >
            <StyledBadge
              overlap="circular"
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              variant="dot"
              >
              <NotificationsNoneOutlinedIcon style={{fontSize:'28px', color:'#000'}}/>
            </StyledBadge>
            </IconButton>
            <Box sx={{display:'flex', alignItems:'center'}}>
              <IconButton
                size="large"
                edge="center"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                color="#000"
              >
                <AccountCircle sx={{ width: 40, height: 40,  }}/>
              </IconButton>
              <Box sx={{display:'flex', alignItems:'start', flexDirection:'column'}}>
                <Typography style={{fontSize:'15px', color:'#000', fontWeight:'bold'}}>Velvet Onion</Typography>
                <Typography style={{fontSize:'16px', color:'#8F95B2', fontWeight:'normal'}}>velvet@deliver.com</Typography>
              </Box>
            </Box>
          </Box>
          <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
    </Box>
  );
}