import React, { useEffect, useState } from 'react';
import './ProgressCircularChart.css';

const ProgressCircularChart = ({ percentage }) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    setProgress(percentage);
  }, [percentage]);

  return (
    <div className="circular-progress">
      <div className="progress-circle">
        <div className="progress-bar-outer" style={{ transform: `rotate(${(progress / 100) * 360}deg)` }}></div>
        <div className="progress-bar-inner"></div>
        <div className="progress-text">
          <span style={{ fontWeight: 'bold' }}>{progress}%</span>
          <br />
          out of 100
        </div>
      </div>
    </div>
  );
};

export default ProgressCircularChart;
