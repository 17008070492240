import * as React from 'react';
import './RegisterForm.css';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import loginImage from '../../assets/images/logo.png';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import { Link } from 'react-router-dom';


const styles = {
  image: {
    width: '20%',
    marginBottom: '10px', 
  },
}
export default function RegisterForm() {
  return (
    <React.Fragment>
      <Container className='login-container' fixed style={{maxWidth: '700px', borderRadius: '8px', display:'flex', justifyContent:'center', alignItems:'center', boxShadow:'0 0 9px rgba(0, 0, 0, 0.1)'}}>
        <Box>
        <img src={loginImage} alt="Login" style={styles.image} />
          <Box sx={{color:'green', padding:'20px'}}>
              <Typography style={{color:'#000', fontWeight:'bold'}} variant='h4'>Create a new account</Typography>
              <Typography style={{color:'gray'}} variant='p'>Use your email to create a new account</Typography>
          </Box>
        <Box
            sx={{
            width: 650,
            maxWidth: '100%',
            padding:'20px',
        }}
        >
            <TextField placeholder='Enter orginazation name' fullWidth label="Organization name" id="fullWidth" style={{marginBottom:'12px'}} />
            <TextField placeholder='Enter user full name' fullWidth label="Full name" id="fullWidth" style={{margin:'12px 0'}}/>
            <TextField placeholder='Enter user contact no' fullWidth label="Email Address " id="fullWidth" style={{margin:'12px 0'}}/>
            <TextField placeholder='Enter user mobile number' fullWidth label="Mobile Number" id="fullWidth" style={{margin:'12px 0'}}/>
            <Typography><Checkbox defaultChecked color="success" />I have read the Terms and Conditions</Typography>
            <Link to='/'>
              <Button fullWidth variant='contained' size="large" style={{ margin:'10px 0', fontWeight:'bold', textTransform:'capitalize',padding:'12px 0'}} color="success"> Sign Up Now</Button>
            </Link>
            <Typography variant='p'>Have an account? <Link style={{textDecoration:'none'}} to='/'><a className='signin-link' href="#">Sign In</a></Link> </Typography>
        </Box>
        </Box>
      </Container>
    </React.Fragment>
  );
}

